/* Menu Header com logotipo, botoes e barra de pesquisa */

        /* Estilos para o menu */
        .menu {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
      }

      .menu li {
          position: relative;
          display: inline-block;
      }

      .menu a {
        font-family: 'Segoe UI', Arial, sans-serif;
          display: block;
          padding: 10px 20px;
          text-decoration: none;
          color: #333;
      }
      .menu a:hover {
        text-decoration: none!important;
    }

      /* Estilos para o dropdown */
      .dropdown {
          position: relative;
      }

      .dropdown ul {
          position: absolute;
          top: 100%;
          left: 0;
          display: none;
          background-color: #fff;
          border: 1px solid #ccc;
          max-width: 1000px;
      }

      .dropdown:hover ul {
          display: block;
      }

      .dropdown li {
          display: block;
      }

      /* Estilos para o texto no dropdown */
      .dropdown a {
          display: block;
          padding: 10px 20px;
          text-decoration: none;
          color: #333;
      }

      /* Estilos para o sub-menu */
      .submenu {
          padding: 10px;
          width: 170px;
          background-color: #fff;
      }
      .submenu li {
        display: inline-block; /* Faz com que os itens do sub-menu fiquem na mesma linha */
        margin-right: 10px; /* Adiciona um espaçamento entre os itens (opcional) */
    }

    .menu-toggle{
      display: none;
    }
        /* Estilos para dispositivos com largura máxima de 768px (pode ajustar conforme necessário) */
        @media (max-width: 768px) {
          .menu {
              display: none;
              list-style: none;
              padding: 0;
          }

          .menu.active {
              display: block;
          }

          .menu li {
              margin-bottom: 10px;
          }

          /* Estilos para o botão de toggle responsivo (pode personalizar conforme desejado) */
          .menu-toggle {
              background-color: transparent;
              border: none;
              cursor: pointer;
              font-size: 24px;
              color: #333;
          }
      }
header {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  background-color: #333;
  padding: 10px 20px;
  background-color: #f4f4f4;
  height: 80px;
}


.menu-section {
  background-color: #fcb900;
  padding: 20px;
  text-align: center;
  height: 30px;
}

/* Menu Mobile*/


.menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  gap: 20px;
}

.menuHeader-central {
  margin: 0 10px;
}

.menu li {
  position: relative;
  /* Para criar espaço para o sublinhado */
  padding-bottom: 5px;
  /* Adiciona um espaçamento abaixo do texto */
}

.menu li:hover {
  cursor: pointer;
  border-bottom: 3px solid #127951;
}

.menuHeader-central a {
  text-decoration: none;
  color: #000;
  font-size: 17px;
  transition: color 0.3s;
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
}

.menuHeader-central a:hover {
  color: #127951;
}

@media screen and (max-width: 768px) {
.menu-section{
  display: none;
}


  header {
    flex-direction: column;
    align-items: flex-start;
  }




  .menu {
    flex-direction: column;
    align-items: center;
    /* Centralize os itens na versão móvel */
  }

  .menuHeader-central {
    margin: 10px 0;
    /* Espaçamento entre os itens de menu na versão móvel */
  }

}