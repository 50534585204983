:root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #000;
  text-decoration: inherit;
}

body {
  margin: 0;
}

h1 {
  font-size: 20px;
  line-height: 1.1;
}

button {
  height: auto;
  font-size: 3vh;
  display: inline-block;
  background: #e4e4e4;
  padding: 3vh;
  margin-top: 2.5vh;
  margin-left: 0.5vh;
  margin-right: 0.5vh;
  margin-bottom: 2.5vh;
  text-align: center;
  box-shadow: 0 3px 10px -2px hsla(150,5%,65%,.5);
  position: relative;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #127951;
  }
  button {
    background-color: #ffc400!important;
    font-size: 2vh!important;
    font-size-adjust: 50%;
    width: auto;
    height: 8vh;
    border-radius: 1vh;
    color: #fff;
    box-shadow: 2px 2px 2px rgba(0,0,0,.21);
    padding: 10px 100px!important;
  }
}

@media screen and (max-width: 768px) {
  button {
    background-color: #ffc400;
    font-size: 2vh!important;
    font-size-adjust: 50%;
    width: auto;
    height: 8vh;
    border-radius: 1vh;
    color: #fff;
    box-shadow: 2px 2px 2px rgba(0,0,0,.21);
    padding: 10px 30px!important;
  }
}