#root {
  margin: 0 auto;
  text-align: center;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

.button-app{
  background-color:#61ce70!important;
}
.padding-top {
  padding-top: 40px;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.buttons-container {
  display: flex;
  flex-direction: column;
}

.buttons-container button {
  width: auto;
  margin: 0 auto;
}

@media (max-width: 768px) { /* Ajuste o valor conforme necessário */
  .buttons-container {
    flex-direction: column;
  }

  .buttons-container button {
    margin: 5px auto; /* Espaçamento entre os botões quando estão empilhados */
  }
}