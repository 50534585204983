.european-handicap-empate-modal {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.modal-title {
  text-align: center;
  margin-bottom: 20px;
}

.range-slider {
  margin-bottom: 20px;
}

.range-slider.home {
  margin-top: 0; /* Para não ter espaço extra no primeiro controle deslizante */
}

.range-slider.away {
  margin-top: 10px; /* Espaço entre os dois controles deslizantes */
}

.range-slider input[type="range"] {
  width: calc(100% - 48px); /* Desconta o tamanho do thumb para que a barra não "pule" ao chegar nos extremos */
  margin: 0 auto; /* Centraliza o slider */
  display: block; /* Garante que cada slider ocupe sua própria linha */
}

.range-slider span {
  display: block; /* Garante que o texto esteja acima do slider */
  text-align: center;
  margin-bottom: 5px;
}

.box-minmax {
  display: flex;
  justify-content: space-between;
  padding: 0 24px; /* Ajusta o padding para alinhar com o thumb */
}

.box-minmax span {
  font-size: 16px;
}

.box-minmax span:nth-child(2) {
  font-size: 20px;
  font-weight: bold;
  color: #333; /* Pode ajustar a cor conforme a sua preferência */
}

/* Custom thumb sizes on hover */
.range-slider input[type="range"]:hover::-webkit-slider-thumb,
.range-slider input[type="range"]:hover::-moz-range-thumb {
  transform: scale(1.2); /* Aumenta o tamanho do thumb ao passar o mouse */
}

@media (max-width: 768px) {
  .range-slider input[type="range"] {
    width: calc(100% - 36px); /* Ajusta a largura para telas menores */
  }

  .box-minmax {
    padding: 0 18px; /* Ajusta o padding para alinhar com o thumb em telas menores */
  }

  .box-minmax span {
    font-size: 14px;
  }

  .box-minmax span:nth-child(2) {
    font-size: 18px;
  }
}
