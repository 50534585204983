.score-container {
    text-align: center;
    padding: 2.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f6f6f6;
    max-width: 300px;
    margin: 40px auto 0;
    /* adicionado 40px de margem superior para dar espaçamento do título */
    position: absolute;
    /* centralização na tela */
    left: 50%;
    /* centralização na tela */
    top: 50%;
    /* centralização na tela */
    transform: translate(-50%, -50%);
    /* centralização na tela */
}

.teams,
.scores {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.scores input {
    width: 30px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
}


button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
}

@media screen and (max-width: 768px) {
    .score-container {
        text-align: center;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #f6f6f6;
        max-width: 250px;
        width: 250px;
        height: 197px;
        margin: 40px auto 0;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -56%);
    }
}