.range-slider {
  width: 100%;
  margin-top: 100px;
}

.range-slider input[type="range"] {
  width: 90%;
  height: 8px;
  -webkit-appearance: none;
  appearance: none;
  background: #ccc;
  outline: none;
  border-radius: 4px;
  padding-top: 10px;  /* Adicione um padding para afastar os spans do controle range */
}

.range-slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px; /* Aumente o tamanho aqui */
  height: 24px; /* E aqui */
  background: #333;
  border-radius: 50%;
  cursor: pointer;
  transition: width 0.3s, height 0.3s;  /* Animação ao passar o mouse sobre a bolinha */
}

.range-slider input[type="range"]:hover::-webkit-slider-thumb {
  width: 28px; /* Tamanho ao passar o mouse */
  height: 28px; /* Tamanho ao passar o mouse */
}

.range-slider input[type="range"]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #333;
  border-radius: 50%;
  cursor: pointer;
}

.box-minmax {
  display: flex;
  justify-content: space-between;
  margin-top: 4px; /* Reduza para aproximar os spans do controle range */
}

.box-minmax span {
  font-size: 16px; /* Aumente o tamanho da fonte */
}

.box-minmax span:nth-child(2) {  /* Estilo específico para o valor do meio */
  font-size: 20px; /* Faça o tamanho da fonte ser maior */
  font-weight: bold;  /* Deixe em negrito */
}

@media (max-width: 768px) {
  .range-slider {
      margin-top: 50px; /* Diminui o espaço no topo para telas menores */
  }

  .range-slider input[type="range"] {
      width: 80%; /* Diminui a largura para centralizar e dar espaço */
  }

  .range-slider input[type="range"]::-webkit-slider-thumb,
  .range-slider input[type="range"]::-moz-range-thumb {
      width: 28px; /* Aumente para uma área de toque maior */
      height: 28px; /* Aumente para uma área de toque maior */
  }

  .box-minmax span {
      font-size: 14px; /* Tamanho da fonte otimizado para mobile */
  }

  .box-minmax span:nth-child(2) {
      font-size: 18px; /* Tamanho da fonte maior para o valor do meio */
  }
}
.range-slider.green .rs-range::-webkit-slider-thumb {
  background-color: green;
}

.range-slider.red .rs-range::-webkit-slider-thumb {
  background-color: red;
}

.range-slider.default .rs-range::-webkit-slider-thumb {
  /* cor padrão, por exemplo: */
  background-color: #333;
}
