.footer {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    background-color: #b0b6b6;  
    color: #000; 
    align-items: center; /* Adicione esta linha */
    height: 170px;

}

.column {
    flex: 1;  /* Garantindo que cada coluna ocupe espaço igual */
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Isso vai centralizar verticalmente o conteúdo da coluna. */
    align-items: center;  /* Isso vai centralizar horizontalmente o conteúdo da coluna. */
}

.column-two {
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Centralização vertical */
    align-items: center;  /* Centralização horizontal */
}
.column-two p {
    margin: 0;
    padding: 0;
}


.footer-column {
    flex: 1;
    padding: 1rem;
    box-sizing: border-box;
}

.footer-column + .footer-column {
    margin-left: 1rem;
}

.image-wrapper img {
    max-height: 80%;  /* Ajuste conforme necessário */
    margin: 0 5px;
}

@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column; /* Muda o layout flex para coluna */
        align-items: center;    /* Centraliza horizontalmente */
        height: auto;           /* Permite que o footer se expanda conforme o conteúdo */
        padding: 1rem;
    }

    .column {
        margin-bottom: 1rem;   /* Espaçamento entre as colunas */
    }

    .column-two p {
        margin: 0.5rem 0;  /* Adiciona um pequeno espaço entre os parágrafos da coluna-two */
    }

    .footer-column + .footer-column {
        margin-left: 0;   /* Remove a margem esquerda das colunas no mobile */
    }
}