.result-container {
    text-align: center;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f6f6f6;
    max-width: 300px;
    margin: 0 auto;
}

.teams, .scores {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
}

.scores input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
}

.result p {
    font-weight: bold;
    color: #333;
}



@media screen and (max-width: 768px) {
    .result-container {
        text-align: center;
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #f6f6f6;
        max-width: 300px;
        margin: 0 auto;
        height: 146px;
    }
    
}
