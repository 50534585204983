.modal-buttons-row {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 1px;
  /* espaçamento entre os botões */
}

.button-closed{
  background-color: red!important;
  margin-bottom: 20px!important;
}
.back-button{
  margin-bottom: 20px!important;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #127951;

  width: 1000px;
  height: 600px;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  background-color: #ffc400;
  color: #000;
  padding: 40px 0px 40px;
  text-align: center;
  border-radius: 5px;
  margin: 0;
  width: 100%;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #4CAF50;
  color: white;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 95%;
    height: 80%;
    max-width: none;
  }

  .modal-title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .modal-footer {
    flex-direction: column;
    gap: 10px;
  }

  button {
    width: 100%;
    padding: 10px;
  }
  .modal-buttons-row { 
    top: 1px;
   }
}
