/* Content.css */

.content-container {
    padding: 5px 100px 40px 100px;
    text-align: center;
}

.content-container h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.content-container p {
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 0px 300px 0px 300px;
}

/* Para telas com largura de até 1200px */
@media (max-width: 1200px) {
    .content-container p {
        padding: 0px 150px 0px 150px;
    }
}

/* Para telas com largura de até 900px */
@media (max-width: 900px) {
    .content-container p {
        padding: 0px 75px 0px 75px;
    }

    .content-container {
        padding: 5px 50px 40px 50px;
        text-align: center;
    }
}

/* Para telas com largura de até 600px */
@media (max-width: 600px) {
    .content-container p {
        padding: 0px 30px 0px 30px;
    }

    .content-container {
        padding: 5px 50px 40px 50px;
        text-align: center;
    }
}

/* Para telas com largura de até 400px */
@media (max-width: 400px) {
    .content-container p {
        padding: 0px 15px 0px 15px;
    }

    .content-container {
        padding: 5px 50px 40px 50px;
        text-align: center;
    }
}