.range-slider {
    width: 100%;
    margin-top: 100px;
  }
  
  .range-slider input[type="range"] {
    width: 90%;
    height: 8px;
    -webkit-appearance: none;
    appearance: none;
    background: #ccc;
    outline: none;
    border-radius: 4px;
    padding-top: 10px;
  }
  
  .range-slider input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
    transition: width 0.3s, height 0.3s;
  }
  
  .range-slider input[type="range"]:hover::-webkit-slider-thumb {
    width: 28px;
    height: 28px;
  }
  
  .range-slider input[type="range"]::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #333;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .box-minmax {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
  }
  
  .box-minmax span {
    font-size: 16px;
  }
  
  .box-minmax span:nth-child(2) {
    font-size: 20px;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .range-slider {
        margin-top: 50px;
    }
  
    .range-slider input[type="range"] {
        width: 80%;
    }
  
    .range-slider input[type="range"]::-webkit-slider-thumb,
    .range-slider input[type="range"]::-moz-range-thumb {
        width: 28px;
        height: 28px;
    }
  
    .box-minmax span {
        font-size: 14px;
    }
  
    .box-minmax span:nth-child(2) {
        font-size: 18px;
    }
  }
  